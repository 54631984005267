<template>
  <section class="loginfrm ftco-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 col-lg-12 padding-0">
          <div class="wrap d-md-flex">
            <div class="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last header-login">
              <div class="text w-100 anivertilogo">
                <h2>Andami – SHMS</h2>
                <p>(PSM Based Safety and Health Management System)</p>
              </div>
            </div>
            <div class="login-wrap p-4 p-lg-5">
              <div class="d-flex">
                <div class="w-100">
                  <h3 class="mb-4">Log In</h3>
                </div>
                <div class="w-100">
                </div>
              </div>
              <q-form @submit="onSubmit" class="signin-form">
                <div class="form-group mb-3">
                  <q-input ref="userIdInput" class="loginText" rounded outlined color="indigo-6" filled v-model="loginForm.userId" placeholder="ID" name="userId" required>
                    <template v-slot:prepend>
                      <q-icon name="person" />
                    </template>
                    <template v-slot:append>
                      <q-icon name="close" @click="loginForm.userId = ''" class="cursor-pointer" />
                    </template>
                  </q-input>
                </div>
                <div class="form-group mb-3">
                  <q-input class="loginText" :type="isPwd ? 'password' : 'text'" rounded outlined color="indigo-6" filled v-model="loginForm.password" placeholder="PASSWORD" name="password" required>
                    <template v-slot:prepend>
                      <q-icon name="password" />
                    </template>
                    <template v-slot:append>
                      <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                      />
                    </template>
                  </q-input>
                </div>
                <div class="form-group">
                  <q-btn
                    :loading="loading"
                    rounded
                    size="lg"
                    icon="lock_open"
                    color="indigo-7"
                    style="width: 100%"
                    type="submit"
                  > Sign In
                    <template v-slot:loading>
                      <q-spinner-gears class="on-left" />
                      Sign In...
                    </template>
                  </q-btn>
                </div>
                <div class="form-group2 d-md-flex">
                  <div class="w-50 text-left">
                    <label class="checkbox-wrap checkbox-primary mb-0">Remember User ID
                    <input type="checkbox" v-model="rememberMe">
                    </label>
                  </div>
                  <div class="w-50 text-right">
                    <q-btn
                      flat
                      dense
                      :label="selectedCountry"
                      icon="language">
                      <q-menu 
                        anchor="bottom left" 
                        self="top middle" 
                        auto-close
                        transition-show="jump-down"
                        transition-hide="jump-up">
                        <q-list class="topMenuShortLanguage">
                          <q-item clickable v-ripple v-for="tag in LangItems"
                            :key="tag.code"
                            :name="tag.codeName"
                            :class="tag.class"
                            style="padding-left: 5px !important;"
                            ref="tag"
                            @click="setDefaultLanguage(tag)">
                            <q-item-section avatar>
                              <country-flag :country="tag.country" class="lanauageFlag" />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label lines="1">{{tag.codeName}}</q-item-label>
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </div>
          </div>
        </div>
      </div>
    <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </section>
</template>

<script>
import Cookies from 'js-cookie'
import CountryFlag from "vue-country-flag";
import { encrypt, decrypt } from '@/js/aes256';
export default {
  components: {
    CountryFlag,
  },
  name: 'login',
  data: () => ({
    selectedCountry: '',
    LangItems: [
      {
        code: 'kr',
        country: 'kr',
        codeName: 'Korea',
      },
      {
        code: 'en',
        country: 'us',
        codeName: 'English',
      },
      {
        code: 'ja',
        country: 'jp',
        codeName: 'Japen',
      },
      {
        code: 'zh_cn',
        country: 'cn',
        codeName: 'Chinise(CN)',
      },
      {
        code: 'zh_tw',
        country: 'tw',
        codeName: 'Chinise(TW)',
      },
    ],
    rememberMe: false,
    isPwd: true,
    slide: 'first',
    loginForm: {
      userId: '',
      password: '',
    },
    loading: false,
    redirect: null,
    popupOptions: {
      target: null,
      title: '',
      visible: false,
      width: '100%',
      isFull: false,
      param: {},
      closeCallback: null,
    },
    lang: '',
  }),
  watch: {
    $route: {
      handler: function(route) {
        if (route.query.permitTypeCd) {
          this.redirect = route.query.redirect + '&permitTypeCd=?' + route.query.permitTypeCd
        } else {
          this.redirect = route.query && route.query.redirect;
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.lang = Cookies.get('language') ? Cookies.get('language') : 'kr';
    this.$_.forEach(this.LangItems, _item => {
      if (this.lang == _item.code) {
        this.selectedCountry = _item.codeName
      }
    })
    this.getLangInfo();
    this.setClass();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let rememberLoginId = window.localStorage.getItem('rememberLoginId');
      if (rememberLoginId !== null) {
        this.$set(this.loginForm, 'userId', decrypt(rememberLoginId));
        this.rememberMe = true;
      }
    },
    onSubmit () {
      this.loading = true;
      let promises = [
        // { func: this.checkValid, exceptionMessage: 'M0000000001' }, /** id, pwd 입력여부 */
        { func: this.actionLogin }, /** login api 호출 */
        { func: this.getLangInfo }, /** 언어별 라벨, 메시지 정보 get */
      ];
      this.$comm.orderedPromise(promises);
    },
    actionLogin() {
      return new Promise((_resolve, _reject) => {
        if (this.rememberMe === true) {
          window.localStorage.setItem('rememberLoginId', encrypt(this.loginForm.userId));
        } else {
          window.localStorage.removeItem('rememberLoginId');
        }
        Cookies.set('language',this.lang)
        this.loading = true;
        this.$store
          .dispatch('LoginByUserAccount', {
            userId: this.loginForm.userId,
            password: encrypt(this.loginForm.password),
          })
          .then(() => {
            _resolve(true);
          })
          .catch(error => {
            if (error && error.response.data.returnCode === 'INIT_PASSWORD') {
              this.changePassword(this.loginForm.userId);
            } else if (error && error.response.data.returnCode === 'PASS_PASSWORD') {
              this.changePassword(error.response.data.message);
              _resolve(false);
            } else if (error && error.response.data.returnCode === 'UNDER_CONSTRUCTION') {
              // 개발서버 접속 못하게 설정하였을 경우
              this.$commMessage.alert(this, {
                title: '알림',
                message: error.response.data.message,
                type: 'info',
              });
            } else {
              this.$commMessage.alert(this, {
                title: '에러', 
                message: error.response.data.message,
                type: 'error',
              });
            }
            this.loading = false;
            _reject(error);
          });
      })
    },
    getLangInfo() {
      return new Promise((_resolve, _reject) => {
        this.$store
          .dispatch('GetNonAuthLangInfo', { lang: Cookies.get('language')})
          .then((hasLang) => {
            console.log('hasLang', hasLang)
            if (hasLang) {
              this.$router.push({ path: this.redirect || '/main/index' }).catch(() => { });
            }
            this.loading = false;
            _resolve(hasLang);
          })
          .catch(error => {
            if (error && error.message === 'Network Error') {
              this.$commMessage.alert(this, {
                title: error.message,
                message: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
                type: 'info',
              });
            } else {
              this.$commMessage.alert(this, {
                title: '로그인 에러',
                message: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
                type: 'info',
              });
              this.$store
                .dispatch('LogOut')
                .then(() => {
                  this.$router.push({ path: this.redirect || '/login' }).catch(() => { });
                })
                .catch(() => {
                  this.$router.push({ path: this.redirect || '/login' }).catch(() => { });
                });
              this.loading = false;
            }
            _reject();
          });
      });
    },
    changePassword(message) {
      this.popupOptions.title = '비밀번호 변경 안내'; 
      this.popupOptions.param = {
        loginId: this.loginForm.userId,
        message: message
      }
      this.popupOptions.target = () => import(`${'./initChangePw.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(message) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (message === 'change') {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '다시 로그인 해주시기 바랍니다.', 
          type: 'info', // success / info / warning / error
        });
        Object.assign(this.$data.loginForm, this.$options.data().loginForm);
        this.$refs['userIdInput'].focus()
      }
    },
    setDefaultLanguage(tag) {
      this.lang = tag.code
      this.selectedCountry = tag.codeName
      this.setClass()
    },
    setClass() {
      this.$_.forEach(this.LangItems, _item => {
        this.$set(_item, 'class', '')
        if (this.lang == _item.code) {
          this.$set(_item, 'class', 'activeLanuage')
        }
      })
    },
  }
};
</script>
<style lang="sass">
.loginfrm
  .header-login
    height: 40vh
  section
    display: block
  .justify-content-center
    -webkit-box-pack: center !important
    -ms-flex-pack: center !important
    justify-content: center !important
  .row
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    margin-right: -15px
    margin-left: -15px
  .text-center
    text-align: center !important
  .mb-5, .my-5
    margin-bottom: 3rem !important
  .col-md-6
    position: relative
    width: 100%
    padding-right: 15px
    padding-left: 15px
  .wrap
    width: 100%
  .text-wrap
    background: linear-gradient(135deg,#3949ab 0%,#3949ab 100%)
    color: #fff
    white-space: normal !important
  .text-wrap
    width: 50%
  .align-items-center
    -webkit-box-align: center !important
    align-items: center !important
  .d-flex
    display: flex !important
  .w-100
    width: 100% !important
  .text-wrap .text h2
    font-weight: 900
    color: #fff
    line-height: 1.5
    font-size: 2rem
    margin-bottom: .5rem
    margin-top: 0
  p
    margin-top: 0
    margin-bottom: 1rem
    display: block
    margin-block-start: 1em
    margin-block-end: 1em
    margin-inline-start: 0px
    margin-inline-end: 0px
  .login-wrap
    position: relative
    background: #fff
    width: 50%
    h3
      font-weight: 300
      line-height: 1.5
      color: #000
      font-size: 1.75rem
      margin-top: 0
  .mb-4
    margin-bottom: 1.5rem !important
  form
    display: block
    margin-top: 0em
  .form-group
    position: relative
  .form-group2
    position: relative
    display: flex
  .mb-3
    margin-bottom: 1rem !important
  .loginText
    .q-field__control
      border-radius: 20px
      background: #f2f2f2
    .q-field__prepend
      padding-right: 10px !important
    .q-field__control-container input.q-field__native
      margin-bottom: 0px !important
    .q-field--float
      .q-field__label
        transform: translateY(-70%) scale(0.75) !important
  .q-btn__content
    .q-icon
      font-size: 1.5rem
      margin-right: 10px


@media (min-width: 768px)
  .loginfrm.ftco-section
    padding: 1em 0
  .loginfrm .container
    max-width: 720px
@media (min-width: 992px)
  .loginfrm.ftco-section
    padding: 1em 0
  .loginfrm .container
    max-width: 800px
  .loginfrm .col-lg-10
    -webkit-box-flex: 0
    flex: 0 0 83.33333%
    max-width: 83.33333%
  .loginfrm .p-lg-5
    padding: 3rem! important
  .loginfrm .col-md-6
    -webkit-box-flex: 0
    -ms-flex: 0 0 50%
    flex: 0 0 50%
    max-width: 50%
  .loginfrm .col-md-12
    -webkit-box-flex: 0
    flex: 0 0 100%
    max-width: 100%
  .loginfrm .d-md-flex
    display: -webkit-box !important
    display: -ms-flexbox !important
    display: flex !important
  .loginfrm .order-md-last
    -webkit-box-ordinal-group: 14
    -ms-flex-order: 13
    order: 13
@media (min-width: 1200px)
  .loginfrm.ftco-section
    padding-top: 7em
  .loginfrm .container
    max-width: 960px

@media (max-width: 991.98px)
  .loginfrm .text-wrap, .loginfrm .login-wrap
    width: 100%

.loginfrm .col-md-12
  position: relative
  width: 100%

.loginfrm .container
  width: 100%
  margin-right: auto
  margin-left: auto

.padding-0
  padding: 0px !important
</style>